import axios from "axios";

// const url = "http://206.189.98.72:3061";
const url = "https://kloudupload.com/api";

export const property_list = (callback) => {
    axios
        .get(`${url}/property_list`, {
            headers: {
                token: sessionStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })

        .then((response) => {
            callback(response.data.response);
        })

        .catch((err) => {
            console.log(err);
        });
};
export const add_story_dropdown = (paylaod, callback) => {
    axios
        .post(`${url}/ref_table`, paylaod, {
            headers: {
                token: sessionStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            let a = response.data;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
            callback(err);
        });
};
export const add_status_dropdown = (paylaod, callback) => {
    axios
        .post(`${url}/ref_table`, paylaod, {
            headers: {
                token: sessionStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            let a = response.data;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
            callback(err);
        });
};
export const add_property = (payload, callback) => {
    axios
        .post(`${url}/add_property`, payload, {
            headers: {
                token: sessionStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            let a = response.data;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
            callback(err);
        });
};

export const get_property_by_id = (id, callback) => {
    axios
        .get(`${url}/get_property_by_id/${id}`, {
            headers: {
                token: sessionStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            let a = response.data.response;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
        });
};
export const update_whole_propertyById = (id, paylaod, callback) => {
    axios
        .put(
            `${url}/update_whole_property/${id}`,
            paylaod,

            {
                headers: {
                    token: sessionStorage.getItem("token"),
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }
        )
        .then((response) => {
            let a = response.data.response;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
        });
};
export const update_single_more_values = (id, paylaod, callback) => {
    axios
        .put(`${url}/update_single_more_values/${id}`, paylaod, {
            headers: {
                token: sessionStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            let a = response.data.response;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const DeleteProperty = (id, paylaod, callback) => {
    axios
        .put(`${url}/delete_property/${id}`, paylaod)
        .then((response) => {
            let a = response.data.response;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
        });
};
export const UploadImage = (paylaod, callback) => {
    axios
        .post(`${url}/uploadfile`, paylaod, {
            headers: {
                Accept: "application/json",
                type: "formData"
            }
        })
        .then((response) => {
            let a = response.data.response;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getLocationIp = (callback) => {
    axios
        .get(
            "http://geolocation-db.com/json/5c628b10-7e44-11eb-815b-3f039c477713"
        )
        .then(({ data }) => {
            console.log(data);
            callback(data);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const AuthLogin = (paylaod, callback, errCallback) => {
    axios
        .post(`${url}/login`, paylaod)
        .then((response) => {
            let a = response.data;
            callback(a);
        })
        .catch((err) => {
            console.log(err);
            errCallback(err.response);
        });
};
