import React, { useState, useEffect } from "react";

import {
    Typography,
    Box,
    Grid,
    Button,
    CircularProgress,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";
import imageCompression from "browser-image-compression";

import { style } from "../MainPage/mainpage";
import ImageList from "@mui/material/ImageList";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import SearchLocation from "../GoogleMap/SearchLocation";
import { UploadImage, add_property, add_status_dropdown } from "../../Actions";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

let deviceWidth = window.innerWidth;
function LotsForm({ handleSubmit, detail, edit, submit, alert, setAlert }) {
    var result;
    console.log("details", detail);
    const classes = style();
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const [form, setForm] = useState({
        latitude: 57.8788914,
        longitude: -101.680266,
        minimumbaths: "0",
        videolink: "",
        country: "",
        postal_code: "",
        status: "",
        mininumbeds: "0",
        city: "",
        iscommunity: "0",
        iscarehome: "0",
        features_2: "",
        squareFeet: "0",
        pst: "",
        pricenew: "",
        images: [],
        isdeleted: "0",
        iscommunityhome: "0",
        iscommunitylots: "1",
        community_name: " ",
        address: "",

        // "community_name": detail && ["community_name"],
        name: generateString(8)
    });
    function generateString(length) {
        let result = " ";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        return result;
    }
    const [imagesArray, setImagesArray] = useState([]);
    const [videosArray, setVideoArray] = useState();
    console.log("detaillls", detail?.showcase);
    const [dropDownStatus, setDropDownStatus] = useState([]);

    const [check, setcheck] = useState();
    const [change, setChange] = useState();
    const [check1, setcheck1] = useState(true);
    const [check2, setcheck2] = useState();
    const [check3, setcheck3] = useState();
    const [radio, setRadio] = useState(false);
    const [gender, setGender] = React.useState("");

    useEffect(() => {
        if (detail?.iscarehome == "1") {
            setcheck(detail?.iscarehome);
        }
        if (detail?.isportfolio == "1") {
            setcheck1(detail?.isportfolio);
        }
        if (detail?.iscommunity == "1") {
            setcheck2(detail?.iscommunity);
            console.log("i am happy");
        }
        if (detail?.showcase == "1") {
            console.log("i am happy");
            setcheck3(detail?.showcase);
        }
        if (detail?.iscarehome === "1" || detail?.iscommunity === "1") {
            setRadio(true);
            // if (detail?.isportfolio === "1") {
            //     setGender("male");
            // }
            if (detail?.iscommunity === "1") {
                setGender("female");
            }
        }
    }, [detail]);

    const [listview, setListview] = useState("grid");
    const [imageUploading, setImageUploading] = useState(false);
    const [videoUploading, setVideoUpload] = useState(false);

    const [showCoordinatesBox, setShowCoordinatesBox] = useState(false);
    const [noImage, setNoImage] = useState(false);
    const [noVideo, setNoVideo] = useState(false);

    const [mapCenterLocation, setmapCenterLocation] = useState({
        lat: 57.8788914,
        lng: -101.680266
    });
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            // 👇 Get input value
            console.log("enter called");
            //   setUpdated(message);
        }
    };
    const formatChange = (e) => {
        e.preventDefault();
        console.log("formDtaa", form.images);
        if (form && form.images && form.images.length == 0) {
            setNoImage(true);
            setAlert({
                status: true,
                severity: "warning",
                message: "Please add image"
            });
            setTimeout(() => {
                setAlert({
                    status: false,
                    severity: "",
                    message: ""
                });
                setNoImage(false);
            }, 3000);
        } else {
            handleSubmit(form);
        }

        // if (change) {
        //     const options = {
        //         minimumFractionDigits: 2,
        //         maximumFractionDigits: 2
        //     };
        //     const formatted = Number(form.pricenew).toLocaleString(
        //         "en",
        //         options
        //     );
        //     // setForm()
        //     handleSubmit({
        //         ...form,
        //         ["pricenew"]: formatted
        //     });
        // } else {
        //     handleSubmit(form);
        // }
        // let str = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    const handlePrice = (e) => {
        setChange(true);
        //     const options = {
        //         minimumFractionDigits: 2,
        //         maximumFractionDigits: 2
        //       };
        //     const formatted = Number(e.target.value).toLocaleString('en', options);
        // // let str = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // console.log("curren",formatted)
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };
    const { isLoaded, loadError } = useJsApiLoader({
        id: "world-map",
        googleMapsApiKey: "AIzaSyCK1M71JxoP14Pgs2UOMTd0USEvjg-6UAM",

        libraries: ["places", "geometry", "drawing"]
    });
    const refreshMap = () => {
        console.log("this is called");
        edit
            ? setmapCenterLocation({
                  lat: parseFloat(detail.latitude) || 0,
                  lng: parseFloat(detail.longitude) || 0
              })
            : getLocation()
            ? getLocation()
            : setmapCenterLocation({
                  lat: 57.8788914,
                  lng: -101.680266
              });
    };
    useEffect(() => {
        console.log(deviceWidth);
        deviceWidth <= 600 ? setListview("list") : setListview("grid");
    }, [deviceWidth]);

    useEffect(() => {
        if (edit) {
            setForm({ ...detail });

            //setcheck(form.iscarehome)
            if (detail.images && detail.images.length > 0) {
                setImagesArray([...detail.images]);
            }
        }
        let paylod = { table_name: "status" };
        add_status_dropdown(paylod, (data) => {
            if (data.statusCode === 200) {
                setDropDownStatus(data?.response.result);
                console.log("data_datayesdd", data?.response.result);
            }
        });
    }, []);

    useEffect(() => {
        edit
            ? setmapCenterLocation({
                  lat: parseFloat(detail.latitude) || 0,
                  lng: parseFloat(detail.longitude) || 0
              })
            : getLocation()
            ? getLocation()
            : setmapCenterLocation({
                  lat: 57.8788914,
                  lng: -101.680266
              });
    }, []);

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
        } else {
            setmapCenterLocation({
                lat: 57.8788914,
                lng: -101.680266
            });
        }
    }
    // Jquery Dependency

    function geoSuccess(position) {
        let lat = parseFloat(position.coords.latitude);
        let lng = parseFloat(position.coords.longitude);
        // alert("lat:" + lat + " lng:" + lng);
        setmapCenterLocation({
            lat,
            lng
        });
        setForm({
            ...form,
            latitude: lat,
            longitude: lng
        });
    }

    function geoError() {}
    const handleVideoUrl = (e) => {
        var val = e.target.value;
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        console.log("value", val);
        if (e.target.value.includes("watch?v=")) {
            let x = e.target.value.split("watch?v=").pop();
            setForm({
                ...form,
                [e.target.name]: "https://www.youtube.com/embed/" + x
            });
        } else if (e.target.value.includes("youtu.be/")) {
            let x = e.target.value.split("/").pop();

            setForm({
                ...form,
                [e.target.name]: "https://www.youtube.com/embed/" + x
            });
        }
    };
    const onChangedImage = (e) => {
        console.log("i am called");
        let file = e.target.files[0];
        setImageUploading(true);

        const optionsCompress = {
            maxSizeMB: 2,
            //maxWidthOrHeight: 500,
            useWebWorker: true,
            fileType: "image/png"
        };
        imageCompression(file, optionsCompress).then((compressedImg) => {
            console.log("compressionimage", compressedImg);

            var file = new File(
                [compressedImg],
                [compressedImg.lastModified + "_" + compressedImg.name],
                { lastModified: new Date().getTime(), type: compressedImg.type }
            );
            console.log("compressionimages", file);

            const data = new FormData();
            data.append("file_name", file);

            UploadImage(data, (data) => {
                console.log(data);
                let newArr = [...imagesArray];
                newArr.push({
                    url: data.image_url
                });
                setImagesArray(newArr);
                setForm({ ...form, images: [...newArr] });
                setImageUploading(false);
            });
        });
    };
    function toggle(value) {
        return !value;
    }
    const onChangedVideo = (e) => {
        let file = e.target.files[0];
        const data = new FormData();
        data.append("file_name", file);

        console.log(e.target.files[0]);
        setVideoUpload(true);
        UploadImage(data, (data) => {
            console.log("videoUpload", data.image_url);
            setVideoArray(data.image_url);

            setForm({ ...form, videolink: data.image_url });
            setVideoUpload(false);
        });
    };
    const onChangedPlace = (point, searchPlace = false) => {
        console.log("onChangedPlace", point);

        // console.log("onChangedPlace", point.latLng.lat());
        // console.log("onChangedPlace", point.latLng.lng());

        if (searchPlace) {
            setForm({ ...form, latitude: point.lat, longitude: point.lng });
            setmapCenterLocation({
                lat: parseFloat(point.lat),
                lng: parseFloat(point.lng)
            });
        } else {
            setForm({
                ...form,
                latitude: point.latLng.lat(),
                longitude: point.latLng.lng()
            });
            //   setmapCenterLocation({
            //     lat: parseFloat(point.latLng.lat()),
            //     lng: parseFloat(point.latLng.lng()),
            //   });
        }
    };

    const Input = styled("input")({
        display: "none"
    });

    const handleDeleteImage = (id) => {
        let newArr = [...imagesArray];
        newArr.splice(id, 1);
        setImagesArray(newArr);
        setForm({ ...form, images: [...newArr] });
        console.log(id);
    };
    const handleDeleteVideo = () => {
        setForm({ ...form, videolink: "" });
        setVideoArray("");
    };
    console.log("form_form", form);

    const changeLocationManual = (e) => {
        if (e.target.name === "latitude") {
            setmapCenterLocation({
                ...mapCenterLocation,
                lat: parseFloat(e.target.value)
            });
            setForm({ ...form, [e.target.name]: e.target.value });
        } else if (e.target.name === "longitude") {
            setmapCenterLocation({
                ...mapCenterLocation,
                lng: parseFloat(e.target.value)
            });
            setForm({ ...form, [e.target.name]: e.target.value });
        }
    };
    return (
        <div>
            <Box sx={{ mt: 12, ml: 1, mr: 1 }}>
                <Typography variant="h4">
                    {edit ? "Edit " : "Add"} Lot
                </Typography>
            </Box>
            <Box sx={{ mt: 1, ml: 1, mr: 1, mb: 2 }}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        flexDirection: `${
                            listview === "list" ? "column" : "row"
                        }`,
                        flexWrap: "nowrap"
                    }}
                >
                    <Grid item sm={3} sx={{ width: "100%" }}>
                        <form
                            onSubmit={
                                (e) => {
                                    formatChange(e);
                                }
                                // handleSubmit(form);

                                // if (
                                //     form &&
                                //     form.images &&
                                //     form.images.length > 0
                                // ) {
                                //     console.log("formsubmitlot", form);
                                //    await formatChange();
                                // } else {
                                //     setNoImage(true);
                                //     setNoVideo(true);
                                //     setAlert({
                                //         status: true,
                                //         severity: "warning",
                                //         message: "Please add video and image"
                                //     });
                                //     setTimeout(() => {
                                //         setAlert({
                                //             status: false,
                                //             severity: "",
                                //             message: ""
                                //         });
                                //     }, 3000);
                                //     setTimeout(() => {
                                //         setNoImage(false);
                                //         setNoVideo(false);
                                //     }, 1000);
                                // }
                            }
                        >
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Address
                                    </label>
                                    <div>
                                        <input
                                            //required
                                            type="text"
                                            className={classes.inputField}
                                            value={form.address}
                                            // min={startDate}
                                            name="address"
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Price
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            className={classes.inputField}
                                            name="price"
                                            value={form.price}
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                             */}
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Price
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            id="input1"
                                            type="text"
                                            className={classes.inputField}
                                            name="pricenew"
                                            value={form.pricenew}
                                            onChange={handlePrice}
                                            onkeydown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Price additional text{" "}
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            className={classes.inputField}
                                            name="pst"
                                            value={form.pst}
                                            onChange={handlePrice}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Lot Size
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            type="number"
                                            className={classes.inputField}
                                            value={form.squareFeet}
                                            name="squareFeet"
                                            // min={startDate}
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Featuress
                                    </label>
                                    <div>
                                        <textarea
                                        style={{height:"auto"}}
                                            // required
                                            type="text"
                                            className={classes.inputField}
                                            value={form.features_2}
                                            // min={startDate}
                                            rows={5}
                                            cols={5}
                                            name="features_2"
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div
                                        className={classes.inputFieldWrapper}
                                        style={{
                                            width: `${
                                                listview === "list"
                                                    ? "98%"
                                                    : "100%"
                                            }`
                                        }}
                                    >
                                        <label className={classes.label}>
                                            Postal Code
                                        </label>
                                        <div>
                                            <input
                                                // required
                                                type="text"
                                                className={classes.inputField}
                                                value={form.postal_code}
                                                // min={startDate}
                                                name="postal_code"
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        [e.target.name]:
                                                            e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Country
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            className={classes.inputField}
                                            // value={endDate}
                                            // min={startDate}
                                            name="country"
                                            value={form.country}
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        City
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            className={classes.inputField}
                                            // value={endDate}
                                            // min={startDate}
                                            name="city"
                                            value={form.city}
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Status
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            type="text"
                                            className={classes.inputField}
                                            // value={endDate}
                                            // min={startDate}
                                            name="status"
                                            value={form.status}
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    {/* <FormControl fullWidth>
                                        

                                        <Select
                                            // // required

                                            value={form["status"]}
                                            defaultValue={form["status"]}
                                            name="status"
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        >
                                            
                                            {dropDownStatus.map((val) => (
                                                <MenuItem
                                                    key={val?.status_id}
                                                    value={val?.status_id}
                                                >
                                                    {val?.status_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                                </div>
                            </div>
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    <label className={classes.label}>
                                        Video Link
                                    </label>
                                    <div>
                                        <input
                                            // required
                                            type="url"
                                            className={classes.inputField}
                                            // value={endDate}
                                            // min={startDate}
                                            name="videolink"
                                            value={form?.videolink}
                                            onChange={
                                                handleVideoUrl
                                                // (e) =>
                                                // setForm({
                                                //     ...form,
                                                //     [e.target.name]:
                                                //         e.target.value
                                                // })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            {form?.videolink && (
                                <iframe
                                    style={{ marginTop: "10px" }}
                                    src={
                                        form?.videolink?.url || form?.videolink
                                    }
                                    height="200"
                                    width="300"
                                    title="Iframe Example"
                                ></iframe>
                            )}
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    {/* <label className={classes.label}>
                                        Select Portfolio
                                    </label> */}
                                    <div>
                                        {/* <input
                                            onChange={(e) => {
                                                setGender(e.target.value);
                                                setcheck2();
                                                setcheck();
                                                setcheck3();

                                                console.log(
                                                    "valuegender",
                                                    e.target.value
                                                );

                                                setForm({
                                                    ...form,
                                                    ["isportfolio"]: "1",
                                                    ["iscarehome"]: "0",
                                                    ["iscommunity"]: "0",
                                                    ["showcase"]: "0",
                                                    ["community_name"]:""
                                                });
                                            }}
                                            type="radio"
                                            checked={gender === "male"}
                                            value="male"
                                        />
                                        <label>Portfolio</label> */}
                                        <br></br>
                                        {/* <input
                                            onChange={(e) => {
                                                setGender(e.target.value);
                                                console.log(
                                                    "valuegender",
                                                    e.target.value
                                                );
                                                setForm({
                                                    ...form,
                                                    ["isportfolio"]: "0",
                                                  
                                                });
                                                // setcheck1(!check1);
                                                // setRadio(true);
                                            }}
                                            type="radio"
                                            checked={gender === "female"}
                                            value="female"
                                        />
                                        <label>Community</label> */}
                                    </div>
                                </div>
                            </div>
                            {/* {radio  && ( */}
                            <div>
                                <div
                                    className={classes.inputFieldWrapper}
                                    style={{
                                        width: `${
                                            listview === "list" ? "98%" : "100%"
                                        }`
                                    }}
                                >
                                    {/* <label className={classes.label}>
                                        Select Community
                                    </label>
                                    <br></br> */}
                                    <br></br>

                                    <FormControl fullWidth>
                                        {/* <InputLabel id="Input label">
                                            Select Community
                                        </InputLabel> */}

                                        <Select
                                            // // required
                                            //id="demo-simple-select"
                                            value={form["community_name"]}
                                            defaultValue={
                                                form["community_name"]
                                            }
                                            name="community_name"
                                            onChange={(e) =>
                                                setForm({
                                                    ...form,
                                                    [e.target.name]:
                                                        e.target.value
                                                })
                                            }
                                        >
                                            <MenuItem value=" ">
                                                <em>Select Community</em>
                                            </MenuItem>
                                            <MenuItem value="brighton">
                                                Brighton
                                            </MenuItem>
                                            <MenuItem value="evergreen">
                                                EverGreen
                                            </MenuItem>
                                            <MenuItem value="aspenridge">
                                                Aspen Ridge
                                            </MenuItem>
                                            <MenuItem value="kensington">
                                                Kensington
                                            </MenuItem>
                                            <MenuItem value="spruce">
                                                Spruce Creek
                                            </MenuItem>
                                            <MenuItem value="greenbryre">
                                                Greenbryre
                                            </MenuItem>
                                            <MenuItem value="marten">
                                                Martensville
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {form["community_name"] === " " ? (
                                        ""
                                    ) : (
                                        <>
                                            <br></br>

                                            <label className={classes.label}>
                                                Select
                                            </label>
                                            <br></br>
                                            <input
                                                onChange={(e) => {
                                                    if (!check2) {
                                                        setForm({
                                                            ...form,
                                                            iscommunity: "1"
                                                        });
                                                    } else {
                                                        setForm({
                                                            ...form,
                                                            iscommunity: "0"
                                                        });
                                                    }

                                                    setcheck2(!check2);
                                                    console.log(
                                                        "callding",
                                                        check2
                                                    );

                                                    //   setForm({ ...form, [e.target.name]: e.target.value })
                                                }}
                                                checked={check2}
                                                type="checkbox"
                                                name="iscommunity"
                                                {...(form.iscarehome == "0" && {
                                                    required: true
                                                })}
                                                value="1"
                                            />
                                            <label>Community </label>
                                            <br></br>
                                            <input
                                                onChange={(e) => {
                                                    if (!check) {
                                                        setForm({
                                                            ...form,
                                                            iscarehome: "1"
                                                        });
                                                    } else {
                                                        setForm({
                                                            ...form,
                                                            iscarehome: "0"
                                                        });
                                                    }

                                                    setcheck(!check);
                                                    // setForm({ ...form, [e.target.name]: e.target.value })
                                                }}
                                                checked={check}
                                                type="checkbox"
                                                name="iscarehome"
                                                {...(form.iscommunity ==
                                                    "0" && { required: true })}
                                                value="1"
                                            />
                                            <label>Carehome </label>

                                            <br></br>
                                            {/* <input
                                                    onChange={(e) => {
                                                        console.log(
                                                            "callding",
                                                            check3
                                                        );

                                                        if (!check3) {
                                                            setForm({
                                                                ...form,
                                                                showcase: "1"
                                                            });
                                                        } else {
                                                            setForm({
                                                                ...form,
                                                                showcase: "0"
                                                            });
                                                        }

                                                        setcheck3(!check3);

                                                        //   setForm({ ...form, [e.target.name]: e.target.value })
                                                    }}
                                                    checked={check3}
                                                    type="checkbox"
                                                    name="showcase"
                                                    value="1"
                                                />
                                                <label>Show Case </label> */}
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* )} */}
                            {/* {noVideo && (
                                <Alert severity="warning">
                                    Please Add Video
                                </Alert>
                            )}

                            {videosArray && (
                                <ImageListItem style={{ marginTop: "10px" }}>
                                    <video width="320" height="240" controls>
                                        <source
                                            src={videosArray}
                                            type="video/mp4"
                                        />
                                        <source
                                            src={videosArray}
                                            type="video/ogg"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                    <ImageListItemBar
                                        sx={{
                                            background:
                                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                                        }}
                                        position="top"
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: "#f44336" }}
                                            >
                                                <DeleteIcon
                                                    onClick={handleDeleteVideo}
                                                />
                                            </IconButton>
                                        }
                                        actionPosition="right"
                                    />
                                </ImageListItem>
                            )}
                           

                            <div style={{ textAlign: "center" }}>
                                <label htmlFor="icon-button-files">
                                    <Input
                                        accept="video/*"
                                        id="icon-button-files"
                                        type="file"
                                        // capture="environment"
                                        onChange={onChangedVideo}
                                        multiple
                                    />
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                    >
                                        {videoUploading ? (
                                            <>
                                                {" "}
                                                <CircularProgress color="primary" />
                                                Uploading....
                                            </>
                                        ) : (
                                            <>
                                                <PhotoCamera
                                                    style={{ fontSize: "40px" }}
                                                />{" "}
 Upload Video (Max 5 Mb.)                                            </>
                                        )}
                                    </IconButton>
                                </label>
                            </div> */}
                            {noImage && (
                                <Alert severity="warning">
                                    Please Add Images
                                </Alert>
                            )}
                            {imagesArray && imagesArray.length > 0 && (
                                <ImageList
                                    sx={{ width: "100%", height: 150 }}
                                    cols={3}
                                    rowHeight={164}
                                >
                                    {imagesArray.map((item, index) => {
                                        console.log("urlll", item.url);
                                        return (
                                            <div>
                                                <ImageListItem
                                                    key={item.img}
                                                    // sx={{ objectFit: "contain" }}
                                                >
                                                    <img
                                                        src={`${item.url}?w=750&h=430&fit=crop&auto=format`}
                                                        srcSet={`${item.url}?w=750&h=430&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                        width={"100%"}
                                                        style={{
                                                            objectFit: "contain"
                                                        }}
                                                    />

                                                    <ImageListItemBar
                                                        sx={{
                                                            background:
                                                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                                                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                                                        }}
                                                        title={item.title}
                                                        position="top"
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{
                                                                    color: "#f44336"
                                                                }}
                                                                aria-label={`star ${item.title}`}
                                                            >
                                                                <DeleteIcon
                                                                    onClick={() =>
                                                                        handleDeleteImage(
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </IconButton>
                                                        }
                                                        actionPosition="right"
                                                    />
                                                </ImageListItem>
                                            </div>
                                        );
                                    })}
                                </ImageList>
                            )}

                            <div style={{ textAlign: "center" }}>
                                <label htmlFor="icon-button-file">
                                    <Input
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        // capture="environment"
                                        onChange={onChangedImage}
                                        multiple
                                    />
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                    >
                                        {imageUploading ? (
                                            <>
                                                {" "}
                                                <CircularProgress color="primary" />
                                                Uploading....
                                            </>
                                        ) : (
                                            <>
                                                <PhotoCamera
                                                    style={{ fontSize: "40px" }}
                                                />{" "}
                                                Upload file
                                            </>
                                        )}
                                    </IconButton>
                                </label>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    disabled={submit}
                                    sx={{ mt: 2 }}
                                    type="submit"
                                    variant="contained"
                                >
                                    {submit && (
                                        <CircularProgress
                                            color="primary"
                                            size={20}
                                        />
                                    )}
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </Grid>

                    <Grid
                        item
                        sm={12}
                        xs={9}
                        // style={{ display: `${listview === "list" ? "none" : ""}` }}
                    >
                        {isLoaded && (
                            <SearchLocation
                                mapZoom={6.5}
                                onChanged={onChangedPlace}
                                centerCoordinates={mapCenterLocation}
                                markerCoordinates={[
                                    {
                                        lat: form.latitude,
                                        lng: form.longitude
                                    }
                                ]}
                                keepStatic={false}
                            />
                        )}
                        <div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value="remember"
                                            color="primary"
                                        />
                                    }
                                    checked={showCoordinatesBox}
                                    label="Show/Hide Coordinates"
                                    onChange={(e) => {
                                        setShowCoordinatesBox(e.target.checked);
                                    }}
                                />
                                {showCoordinatesBox ? (
                                    <div>
                                        <div
                                            className={
                                                classes.inputFieldWrapper
                                            }
                                            style={{
                                                width: `${
                                                    listview === "list"
                                                        ? "98%"
                                                        : "100%"
                                                }`
                                            }}
                                        >
                                            <label className={classes.label}>
                                                Latitude
                                            </label>
                                            <div>
                                                <input
                                                    // required
                                                    type="text"
                                                    className={
                                                        classes.inputField
                                                    }
                                                    // value={endDate}
                                                    // min={startDate}
                                                    name="latitude"
                                                    value={form.latitude}
                                                    onChange={(e) => {
                                                        setForm({
                                                            ...form,
                                                            [e.target.name]:
                                                                e.target.value
                                                        });
                                                        changeLocationManual(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                classes.inputFieldWrapper
                                            }
                                            style={{
                                                width: `${
                                                    listview === "list"
                                                        ? "98%"
                                                        : "100%"
                                                }`
                                            }}
                                        >
                                            <label className={classes.label}>
                                                Longitude
                                            </label>
                                            <div>
                                                <input
                                                    // required
                                                    type="text"
                                                    className={
                                                        classes.inputField
                                                    }
                                                    value={form.longitude}
                                                    // min={startDate}
                                                    name="longitude"
                                                    onChange={(e) => {
                                                        setForm({
                                                            ...form,
                                                            [e.target.name]:
                                                                e.target.value
                                                        });
                                                        changeLocationManual(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "15px"
                            }}
                        >
                            <Button variant="contained" onClick={refreshMap}>
                                Recenter Map
                            </Button>
                        </div>
                        {/* <div style={{ padding: "3rem" }}>
                            {alert?.status && (
                                <Alert severity={alert?.severity}>
                                    {alert?.message}
                                </Alert>
                            )}
                        </div> */}
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default LotsForm;
