import React, { useEffect, useState } from "react";
import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";

import MainPage from "./components/MainPage";
import MainLots from "./components/MainPage/ViewLots";
import DenseAppBar from "./components/MainPage/DenseAppBar";
// import SignIn from "./components/SignIn";

import AddProperty from "./components/MainPage/AddPropery";
import EditProperty from "./components/MainPage/EditProperty";
import AddLots from "./components/MainPage/AddLots";

function ProtectedRoutes() {
    const [token, settoken] = useState(sessionStorage.getItem("token"));

    useEffect(() => {
        settoken(sessionStorage.getItem("token"));
    }, []);

    return (
        <>
            {!token ? <Redirect to="/" /> : ""}
            {token ? (
                <div>
                    <DenseAppBar />
                    <Switch>
                        <div style={{ marginTop: "12rem" }}>
                            <Route exact path={"/main"} component={MainPage} />
                            <Route
                                exact
                                path={"/main_lots"}
                                component={MainLots}
                            />
                            <Route
                                exact
                                path={"/add"}
                                component={AddProperty}
                            />
                            <Route exact path={"/lots"} component={AddLots} />
                            <Route
                                exact
                                path={"/edit/:id"}
                                component={EditProperty}
                            />
                        </div>
                    </Switch>
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default ProtectedRoutes;
