import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useHistory } from "react-router-dom";
import { AuthLogin } from "../../Actions";
import { Alert, CircularProgress } from "@mui/material";
import { setCookie, getCookie, deleteCookie } from "../../helperFunction";
import logoLogin from "../logo.png";

export default function SignIn() {
    const history = useHistory();
    const [errMsg, setErrMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({ email: "", password: "" });
    const [rememberMe, setRememberMe] = React.useState(false);

    React.useEffect(() => {
        let email = getCookie("email");
        let pass = getCookie("password");
        console.log(pass);
        if (email && pass) {
            const newValues = {
                email: email,
                password: pass
            };

            setForm({ ...newValues });
            setRememberMe(true);
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (rememberMe) {
            setCookie("email", form.email, 30);
            setCookie("password", form.password, 30);
        } else {
            deleteCookie("email");
            deleteCookie("password");
        }

        if (form.email && form.password) {
            setLoading(true);
            AuthLogin(
                {
                    email: form.email,
                    password: form.password
                },
                (res) => {
                    setLoading(false);
                    console.log("now here asad", res);
                    sessionStorage.setItem("token", res.response.token);
                    history.push("/main");
                },
                (err) => {
                    console.log(err);
                    setLoading(false);
                    setErrMsg(err.data.message);
                    setTimeout(() => {
                        setErrMsg("");
                    }, 5000);
                }
            );
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <img src={logoLogin} alt="logo" height="200" width="200" />

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        variant="filled"
                        fullWidth
                        id="email"
                        label="User name"
                        name="email"
                        autoComplete="email"
                        type={"text"}
                        autoFocus
                        value={form.email}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                [e.target.name]: e.target.value
                            })
                        }
                    />
                    <TextField
                        margin="normal"
                        required
                        variant="filled"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={form.password}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                [e.target.name]: e.target.value
                            })
                        }
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        checked={rememberMe}
                        label="Remember me"
                        onChange={(e) => {
                            setRememberMe(e.target.checked);
                        }}
                    />
                    <Button
                        type="submit"
                        disabled={loading}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "capitalize" }}
                    >
                        {loading && (
                            <CircularProgress color="secondary" size={20} />
                        )}
                        Login
                    </Button>
                </Box>
                {errMsg && (
                    <Alert sx={{ width: "100%" }} severity="error">
                        {errMsg}
                    </Alert>
                )}
            </Box>
        </Container>
    );
}
