import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import logo from "../logo.png";

function DenseAppBar() {
    const history = useHistory();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="inherit">
                <Toolbar variant="dense">
                    <IconButton onClick={() => history.goBack()}>
                        <ArrowBackIcon fontSize="24px" color="none" />
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        component="div"
                        sx={{ flexGrow: 1, mt: 1, textAlign: "center" }}
                    >
                        <img src={logo} alt="logo" height="150" width="150" />
                    </Typography>

                    <a
                        style={{
                            textTransform: "capitalize",
                            cursor: "pointer",
                            color: "#126782"
                        }}
                        onClick={() => {
                            sessionStorage.removeItem("token");
                            history.push("/");
                        }}
                        href
                    >
                        Logout
                    </a>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default DenseAppBar;
