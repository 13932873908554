
import { makeStyles } from "@mui/styles";
import pic2 from "../pic2.jpg";

export const style = makeStyles({
  iFormControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #a9e8e3 !important",
    },
    "& .Mui-focused": {
      border: "2px solid #a9e8e3 !important",
    },
  },
  imgGalerry: {
    backgroundColor: "#000",
    height: "463px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url(${pic2})`,
  },
  cancelButton: {
    width: "122px",
    height: "40px",
    objectFit: "contain",
    borderRadius: "12px",
    border: "solid 1px #4fd1c5",
    backgroundColor: "#fff",
    color: "#4fd1c5",
  },
  applyButton: {
    width: "122px",
    height: "40px",
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    // margin: "204px 30px 0 9px",
    // padding: "0 8px",
    objectFit: "contain",
    borderRadius: "12px",
    backgroundColor: "#4fd1c5",
    color: "#ffffff",
    "& .MuiButton": {},
  },
  TopBackground: {
    width: "100%",
    height: "42px",
    // margin: "0px 0px 10px 10px",
    objectFit: "contain",
    backgroundColor: "#2d3748",
    borderRadius: "15px 15px 0px 0px",
  },
  TopTitle: {
    // width: "168.2px",
    // height: "25px",
    padding: "8px",
    flexGrow: 0,
    fontFamily: "Helvetica",
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.4,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
  },
  title: {
    background: "#2d3748",
    color: "white",
    textAlign: "center",
    height: 30,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    fontSize: 18,
    paddingTop: 8,
    fontFamily: "Helvetica",
  },
  inputFieldWrapper: {
    width: "100%",
  },
  inputFieldWrappernew: {
    width: "33.33%",
    float: "left",
  },
  inputField: {
    width: "100%",
    height: 30,
    // borderRadius: 10,
   // marginTop: 10,
    fontSize: 16,
    fontFamily: "Helvetica",
    border: "1px solid #e2e8f0",
    color: "grey",
  },
  showReportBtn: {
    background: "#4fd1c5",
    width: "90%",
    // height: "45px",
    border: `2px solid #4fd1c5`,
    borderRadius: 10,
    color: "white",
    fontSize: 13,
    fontWeight: "bold",

    marginTop: 30,
    marginBottom: 10,
    "&:hover": {
      background: "#4fd1c5",
    },
  },
  label: {
    color: "#2d3748",
    fontSize: 14,
    fontWeight: 500,
  },
  btnHome: {},
  SearchStyle: {
    width: "80%",
    marginBottom: "auto",
    marginTop: "0px",
    height: "50px",
    objectFit: "contain",
    borderRadius: "10px",
    border: " solid 1px #4fd1c5",
    backgroundColor: "white",
    zIndex: 2000,
    marginLeft: "auto",
    paddingTop: 5,
  },
  inputSearchStyle: {
    outline: "none",
    width: "55%",
    background: "white",
    border: "none",
    borderRadius: "10px",
  },
  field: {
    objectFit: "contain",
    fontFamily: "Helvetica",
    fontSize: "18px",
  },
});
