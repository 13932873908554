import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
    get_property_by_id,
    update_whole_propertyById
} from "../../../Actions";
import LotsForm from "../../LotsForm";
import PropertyForm from "../../propertyForm";
const EditProperty = () => {
    const history = useHistory();
    const { id } = useParams();
    const [detail, setDetail] = useState({});
    const [submit, setSubmit] = useState(false);
    const [data, setData] = useState();
    useEffect(() => {
        get_property_by_id(id, (data) => {
            console.log("dtaaaa", typeof data.result.iscommunitylots);
            setDetail(data.result);
            setData(data?.result?.iscommunitylots);
        });
    }, []);

    const handleSubmit = (form) => {
        setSubmit(true);
        setTimeout(() => {
            setSubmit(false);
        }, 10000);
        console.log("valuemap",form);
        let newform = { ...form };
        delete newform["updated-on"];
        delete newform["updated-by"];
        delete newform["created-on"];
        delete newform["created-by"];
        delete newform["published-on"];
        delete newform["published-by"];
        delete newform["id"];


        let payload;
{form.image||form.images?
         payload = {
            
            fields: {
                ...newform,
                image: {
                    ...form.images[0]
                }
            }
        }
        :
        payload = {
            
            fields: {
                ...newform,
                
            }
        }
    }
        
        update_whole_propertyById(id, payload, (data) => {
            console.log(data);
            history.push("/main");
            setSubmit(false);
        });
    };

    return (
        <>
            {data =="1" ? (
                <>
                    {detail && Object.entries(detail).length > 0 ? (
                        <LotsForm
                            edit={true}
                            detail={detail}
                            handleSubmit={handleSubmit}
                            submit={submit}
                        />
                    ) : (
                        <div
                            style={{
                                justifyContent: "center",
                                textAlign: "center",
                                padding: "40%"
                            }}
                        >
                            <CircularProgress color="primary" />
                        </div>
                    )}
                </>
            ) : (
                <>
                    {detail && Object.entries(detail).length > 0 ? (
                        <PropertyForm
                            edit={true}
                            detail={detail}
                            handleSubmit={handleSubmit}
                            submit={submit}
                        />
                    ) : (
                        <div
                            style={{
                                justifyContent: "center",
                                textAlign: "center",
                                padding: "40%"
                            }}
                        >
                            <CircularProgress color="primary" />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default EditProperty;
