import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Delete from "@mui/icons-material/Delete";
import home from "../../home.png";
import { Edit } from "@mui/icons-material";
import bed from "../../bed.png";
import shower from "../../shower.png";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Grid, TextField } from "@mui/material";
import { property_list, update_single_more_values } from "../../../Actions";
import { useHistory } from "react-router-dom";
import { style } from "../mainpage";

let w = window.innerWidth;
export default function MainLots() {
    const [listView, setListView] = useState("grid");
    const [data, setData] = useState([]);
    const classes = style();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log("mount");
        console.log(w);
        if (w <= 600) {
            setListView("list");
        } else {
            setListView("grid");
        }

        setLoading(true);
        handleViewList();
    }, []);
    const handleDelete = (id, property) => {
        const paylaod = {
            fields: {
                isdeleted: "1"
            }
        };

        update_single_more_values(id, paylaod, (data) => {
            property_list((data) => {
                const dd = data.result.items.filter(function (number) {
                    return number.isdeleted !== "1";
                });
                const filtered = dd.filter(function (number) {
                    return (
                        number.iscommunitylots === "1" ||
                        number.iscarehome === "1"
                    );
                });
                setData(filtered);
                setLoading(false);
            });
        });
    };

    const handleViewList = (val) => {
        property_list((data) => {
            const dd = data.result.items.filter(function (number) {
                return number.isdeleted !== "1";
            });
            const filtered = dd.filter(function (number) {
                return (
                    number.iscommunitylots === "1" || number.iscarehome === "1"
                );
            });
            setData(filtered);
            setLoading(false);
        });
    };

    const handleSearch = (e) => {
        let value = e.target.value;
        property_list((data) => {
            const dd = data.result.items.filter(function (number) {
                return number.isdeleted !== "1";
            });
            const filtered = dd.filter(function (number) {
                return (
                    number.iscommunitylots === "1" || number.iscarehome === "1"
                );
            });
            const filteredSearch = filtered.filter((entry) =>
                Object.values(entry).some(
                    (val) => typeof val === "string" && val.includes(value)
                )
            );
            console.log("val_val", filteredSearch);
            setData(filteredSearch);
            setLoading(false);
        });
    };
    const handleClick = (property) => {
        const { id } = property;
        history.push(`/edit/${id}`);
    };

    return (
        <>
            <Box sx={{ mt: 12, ml: 1, mr: 1 }}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                            textAlign: "center" // this does the magic
                        }}
                    >
                        <Button
                            style={{ margin: "5px", width: "180px" }}
                            variant="contained"
                            onClick={() => {
                                history.push("/add");
                            }}
                        >
                            Add Property
                        </Button>

                        <Button
                            style={{ margin: "5px", width: "180px" }}
                            variant="contained"
                            onClick={() => {
                                history.push("/lots");
                            }}
                        >
                            Add Lot
                        </Button>

                        <Button
                            style={{ margin: "5px", width: "180px" }}
                            variant="contained"
                            onClick={() => {
                                history.push("/main");
                            }}
                        >
                            View Properties
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <div>
                            <Typography variant="h4">List of Lots</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={6}>
                        <div>
                            <TextField
                                fullWidth
                                placeholder="Search by Address, Community"
                                type="search"
                                onChange={handleSearch}
                                // className={classes.inputField}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 1, ml: 1, mr: 1 }}>
                {loading && (
                    <div
                        style={{
                            justifyContent: "center",
                            textAlign: "center",
                            padding: "10%"
                        }}
                    >
                        <CircularProgress color="primary" />
                    </div>
                )}
                <div>
                    <Grid
                        container
                        alignItems="flex-start"
                        justify="flex-start"
                        direction="row"
                        spacing={6}
                    >
                        {data.map((property, index) => {
                            return (
                                <Grid
                                    item
                                    md={listView === "list" ? 12 : 4}
                                    sm={listView === "list" ? 12 : 6}
                                    xs={12}
                                    key={index.toString()}
                                >
                                    <MediaCard
                                        property={property}
                                        listView={listView}
                                        handleClick={handleClick}
                                        handleDelete={handleDelete}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </Box>
        </>
    );
}

function MediaCard({ property, handleClick, handleDelete, listView }) {
    function dateFormate(cell) {
        let d;
        let ye;
        let mo;
        let da;
        if (cell) {
            d = new Date(cell);
            ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
            mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
            da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        }
        return `${ye}-${mo}-${da}`;
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen1 = (id, property) => {
        console.log("callinger");
        handleDelete(id, property);
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card sx={{ maxWidth: "100%%" }}>
            <CardMedia
                component="img"
                height="140"
                image={property?.image?.url}
                alt="green iguana"
            />
            <CardContent>
                <div style={{ display: "flex" }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {property.address}
                    </Typography>
                    <div style={{ marginLeft: "auto" }}>
                        <Edit
                            onClick={() => handleClick(property)}
                            style={{ color: "#126782" }}
                        />
                        <Delete
                            onClick={handleClickOpen}
                            style={{ color: "red" }}
                        />
                    </div>
                </div>
                <div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Are You Sure to Delete Collection?"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose}>Disagree</Button>
                            <Button
                                onClick={() =>
                                    handleClickOpen1(property.id, property)
                                }
                                autoFocus
                            >
                                Agree
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Typography variant="body2" color="secondary.light">
                    {dateFormate(property["created_on"])}
                </Typography>
            </CardContent>
            <CardActions>
                <Grid style={{ fontSize: "10px" }} container spacing={1}>
                    <Grid item sm={4}>
                        Price
                        <br />
                        {"$"}
                        {property.pricenew}
                        {property.pst ? "+" : ""}
                        {property?.pst}
                    </Grid>
                    <Grid item sm={8}>
                        <Grid container spacing={1}>
                            <Grid item sm={3}>
                                <img
                                    style={{ marginLeft: "13px" }}
                                    src={home}
                                    width={30}
                                    alt="img"
                                />
                            </Grid>
                            <Grid item sm={9}>
                                Lot Size
                                <br />
                                <p style={{ margin: 0, marginLeft: 20 }}>
                                    {property.squareFeet}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item sm={3}>
                        <Grid container spacing={1}>
                            <Grid item sm={6}>
                                <img src={bed} width={30} alt="bed" />
                            </Grid>
                            <Grid style={{ marginTop: "7px" }} item sm={6}>
                                {property.maximumbeds}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <Grid container spacing={1}>
                            <Grid item sm={6}>
                                <img src={shower} width={30} alt="shower" />
                            </Grid>
                            <Grid style={{ marginTop: "7px" }} item sm={6}>
                                {property.maximumbaths}
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </CardActions>
        </Card>
    );
}
