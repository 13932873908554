import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { add_property } from "../../../Actions";
import PropertyForm from "../../propertyForm";

const AddProperty = () => {
    const history = useHistory();
    const [submit, setSubmit] = useState(false);
    const [checkImage,setCheckImage]=useState(false)
    const [alert, setAlert] = useState({
        status: false,
        severity: "",
        message: ""
    });
    useEffect(() => {}, []);

    const handleSubmit = (form) => {
        setSubmit(true);
        setTimeout(() => {
            setSubmit(false);
        }, 10000);
        console.log("added", form.images);

let payload;
{form.images?
         payload = {
            
            fields: {
                ...form,
                _archived: false,
                _draft: false,
                image: {
                    ...form?.images[0]
                }
            }
        }
        :
        payload = {
            
            fields: {
                ...form,
                _archived: false,
                _draft: false,
                
            }
        }
    }
        add_property(payload, (data) => {
            console.log("data_data", data);
            if (data.statusCode === 200) {
                setAlert({
                    status: true,
                    severity: "success",
                    message: "Property Add"
                });
                setTimeout(() => {
                    history.push("/main");
                    setSubmit(false);
                }, 3000);
            } else {
                setAlert({
                    status: true,
                    severity: "error",
                    message: "Error"
                });
                setTimeout(() => {
                    setAlert({
                        status: false,
                        severity: "",
                        message: ""
                    });
                }, 3000);
            }
        });
    };

    return (
        <>
            <PropertyForm
                handleSubmit={handleSubmit}
                submit={submit}
                alert={alert}
                setAlert={setAlert}
            />
        </>
    );
};

export default AddProperty;
