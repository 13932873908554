import React, { useEffect, useState } from "react";

import { GoogleMap, Marker, StandaloneSearchBox } from "@react-google-maps/api";

const containerStyle = {
    // width: "400px",
    height: "400px"
};

let searchBox;
let bounds;

function MyComponent({
    markerCoordinates,
    onChanged,
    centerCoordinates,
    offLoadScript = true,
    disableOnclick = false,
    keepStatic = false,
    mapZoom
}) {
    let centerCoordinatesCache;
    const [centerC, setcenterC] = useState(centerCoordinates);
    const [markers, setmarkers] = useState(markerCoordinates);

    useEffect(() => {
        setmarkers(markerCoordinates);
        console.log("props_markerCoordinates:", markerCoordinates);
    }, [markerCoordinates]);

    useEffect(() => {
        console.log("centerCoordinates: ", centerCoordinates);
        centerCoordinatesCache = centerCoordinates;
        if (centerCoordinates.lat) {
            console.log("centerCoordinates.lat: ", centerCoordinates.lat);

            setcenterC({ ...centerCoordinates });
        }
    }, [centerCoordinates]);

    const onLoad = (ref) => (searchBox = ref);
    const onPlacesChanged = () => {
        console.log(searchBox.getPlaces());
        let places = searchBox.getPlaces();

        const bounds = new window.google.maps.LatLngBounds();

        places.forEach((place) => {
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });

        const nextMarkers = places.map((place) => {
            return {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
        });
        console.log("nextMarkers: ", nextMarkers[0]);
        console.log("centerC: ", centerC);

        // do it in future use _get
        // const nextCenter = _.get(nextMarkers, "0.position", centerC);
        setcenterC(nextMarkers[0]);
        setmarkers([...nextMarkers]);
        onChanged(nextMarkers[0], true);
    };

    return (
        (offLoadScript && (
            <GoogleMap
                // ref={}
                onLoad={(load) => {
                    console.log("mapLoad: ", load);
                }}
                mapElement={{ backgroundColor: "red" }}
                options={{
                    // display: "none",
                    zoomControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false
                }}
                id="world-map"
                mapContainerStyle={containerStyle}
                center={centerCoordinates}
                zoom={mapZoom || 6.5}
                onClick={(point) => {
                    console.log("onMapClicked: ", point);
                    console.log("onMapClicked_lat: ", point.latLng.lat());
                    console.log("onMapClicked_lng: ", point.latLng.lng());
                    if (!disableOnclick) {
                        onChanged(point);
                    }
                }}
            >
                <>
                    {console.log(centerCoordinates, "imran khan")}
                    {!keepStatic && (
                        <StandaloneSearchBox
                            onLoad={onLoad}
                            onPlacesChanged={onPlacesChanged}
                            bounds={bounds}
                        >
                            <input
                                type="text"
                                placeholder="Search location "
                                style={{
                                    boxSizing: "border-box",
                                    border: "1px solid black",
                                    width: "100%",
                                    // background: theme.palette.background.default,
                                    height: "32px",
                                    marginTop: "0px",
                                    // marginLeft: "193px",
                                    padding: "0 12px",
                                    borderRadius: "3px",
                                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                                    fontSize: "14px",
                                    outline: "none",
                                    textOverflow: "ellipses",
                                    // zIndex: "2000",
                                    position: "absolute"
                                }}
                            />
                        </StandaloneSearchBox>
                    )}
                    {markerCoordinates &&
                        markerCoordinates.map((marker, index) => {
                            if (
                                Object.entries(marker).length > 0 &&
                                marker.lat &&
                                marker.lng
                            ) {
                                return (
                                    <Marker
                                        draggable="true"
                                        position={marker}
                                    />
                                );
                            }
                        })}
                </>
            </GoogleMap>
            // </LoadScript>
        )) || (
            <>
                <GoogleMap
                    id="world-map"
                    // ref={ref2}
                    onLoad={(load) => {}}
                    mapElement={{ backgroundColor: "red" }}
                    options={{ display: "none" }}
                    mapContainerStyle={containerStyle}
                    // center={centerCoordinates}
                    center={centerC}
                    zoom={mapZoom || 6.5}
                    onClick={(point) => {
                        console.log("onMapClicked_lat: ", point.latLng.lat());
                        console.log("onMapClicked_lng: ", point.latLng.lng());

                        // if (!disableOnclick) {
                        //   onChanged(point);
                        // }
                    }}
                >
                    <>
                        {!keepStatic && (
                            <StandaloneSearchBox
                                onLoad={onLoad}
                                onPlacesChanged={onPlacesChanged}
                                bounds={bounds}
                            >
                                <input
                                    type="text"
                                    placeholder="Customized your placeholder"
                                    style={{
                                        boxSizing: "border-box",
                                        border: "1px solid transparent",
                                        width: "240px",
                                        // background: theme.palette.background.default,
                                        height: "32px",
                                        marginTop: "0px",
                                        marginLeft: "193px",
                                        padding: "0 12px",
                                        borderRadius: "3px",
                                        boxShadow:
                                            "0 2px 6px rgba(0, 0, 0, 0.3)",
                                        fontSize: "14px",
                                        outline: "none",
                                        textOverflow: "ellipses",
                                        zIndex: "2000",
                                        position: "absolute"
                                    }}
                                />
                            </StandaloneSearchBox>
                        )}
                        {markers &&
                            markers.map((marker, index) => {
                                if (
                                    Object.entries(marker).length > 0 &&
                                    marker.lat &&
                                    marker.lng
                                ) {
                                    return <Marker position={marker} />;
                                }
                            })}
                    </>
                </GoogleMap>
            </>
        )
    );
}

export default React.memo(MyComponent);
