import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import SignIn from "./components/SignIn";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { ThemeProvider as ThemeProviderLegacy } from "@mui/styles";
import ProtectedRoutes from "./ProtectedRoutes";

const theme = createTheme({
    palette: {
        primary: {
            main: "#126782"
        },
        secondary: {
            light: "#219EBC",
            main: "#f0d0d0",
            contrastText: "#000000"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2
    }
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <ThemeProviderLegacy theme={theme}>
                <Router>
                    <Switch>
                        <Route exact path={"/"} component={SignIn} />
                        <ProtectedRoutes />
                    </Switch>
                </Router>
            </ThemeProviderLegacy>
        </ThemeProvider>
    );
};

export default App;
